<template>
  <div class="settings-security-payment-container">
    <!-- Password block -->
    <h2>Change Password</h2>

    <div class="settings-security-payment-row">
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            @input="changeInputStatus()"
            v-model="passwordsArr.currentPassword"
            :class="{ error: checkInputHasError('old_password') }"
            type="password"
            placeholder="Current password"
            id="settings-cur-password">
          <label for="settings-cur-password">Current password</label>
        </div>
      </div>

      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            @input="changeInputStatus()"
            v-model="passwordsArr.newPassword"
            :class="{ error: checkInputHasError('new_password') }"
            type="password"
            autocomplete="new-password"
            placeholder="New password"
            id="settings-new-password">
          <label for="settings-new-password">New password</label>
        </div>
      </div>

      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            @input="changeInputStatus()"
            v-model="passwordsArr.confirmPassword"
            :class="{ error: checkInputHasError('new_password_confirmation') }"
            type="password"
            autocomplete="new-password"
            placeholder="Confirm new password"
            id="settings-confirm-password">
          <label for="settings-confirm-password">Confirm new password</label>
        </div>
      </div>
    </div>

    <div v-if="!check.maxLength || !check.hasNumber || !check.hasNumber || !check.hasLowerCase || !check.hasUpperCase || !check.hasSpecial || !check.confirmed" class="validation-popup">
      <p v-if="!check.maxLength">Password not contains at least 8 characters</p>
      <p v-if="!check.hasNumber">Password not contains at least one number (0-9)</p>
      <p v-if="!check.hasLowerCase">Password not contains at least one lowercase letter (a-z)</p>
      <p v-if="!check.hasUpperCase">Password not contains at least one capital letter (A-Z)</p>
      <p v-if="!check.hasSpecial">Password not contains at least one symbol (!@#$%^&*)</p>
      <p v-if="!check.confirmed">Password not confirmed</p>
    </div>

    <div v-else class="settings-security-payment-text">
      <p>Password Requirements:</p>
      <ul>
        <li>Minimum 8 characters long - the more, the better</li>
        <li>At least one lowercase character</li>
        <li>At least one number, symbol, or whitespace character</li>
      </ul>
    </div>

    <ValidateErrors :fieldsErrors="validationErrors.errors"/>

    <!-- SSO block -->
    <div class="settings-security-sso-wrap">
      <h2>Authorization methods (SSO)</h2>
      <div class="settings-security-sso-block settings-security-sso-google-block">
        <div class="settings-security-sso-info-block">
          <p>
            <img src="@/assets/img/icons/google.svg" alt="google"/>
            <span>Google</span>
          </p>

          <p v-if="profileInfo.security && profileInfo.security.sso_data && profileInfo.security.sso_data.google">
            {{ replaceEmailUsername(profileInfo.security.sso_data.google.email) }}
          </p>
        </div>

        <div class="settings-security-sso-buttons-block">
          <template v-if="profileInfo.security && profileInfo.security.sso_data && profileInfo.security.sso_data.google">
            <button class="basic_delete-btn" @click="handleSocialDisconnect('google')" :disabled="ssoActionState">
              Disconnect
            </button>
          </template>
          <template v-else>
            <button class="basic_green-btn" @click="handleSocialConnect('google')" :disabled="ssoActionState">
              Connect
            </button>
          </template>
        </div>
      </div>
    </div>

    <!-- Buttons block -->
    <SettingsActionButtons
      :inputChanged="inputChanged"
      @saveSettingsData="saveSettingsData()"
      @resetSettingsData="resetSettingsData"/>
  </div>
</template>

<script>
import SettingsActionButtons from "@/components/dashboard/settings/SettingsActionButtons"
import changeInputStatus from "@/mixins/settings/changeInputStatus"
import {mapGetters} from "vuex"
import checkInputHasError from "@/mixins/checkInputHasError"
import {UPDATE_PASSWORD_PROFILE_INFO} from "@/store/actions"
import ValidateErrors from "@/components/basic/ValidateErrors"
import {
  DISCONNECT_USER_SSO_SERVICE,
} from "../../../store/actions"

export default {
  name: "SettingsSecurity",
  components: {
    ValidateErrors,
    SettingsActionButtons,
  },
  mixins:[
    changeInputStatus,
    checkInputHasError,
  ],
  computed: {
    ...mapGetters([
      'validationErrors',
      'profileInfo',
    ])
  },
  data(){
    return{
      errorMessage: false,
      passwordsArr:{
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      password: '',
      check: {
        maxLength: true,
        hasNumber: true,
        hasLowerCase: true,
        hasUpperCase: true,
        hasSpecial: true,
        confirmed: true,
      },
      ssoActionState: false,
    }
  },
  methods:{
    resetSettingsData(){
      this.passwordsArr.newPassword = null
      this.passwordsArr.currentPassword = null
      this.passwordsArr.confirmPassword = null
      this.inputChanged = false
    },
    async saveSettingsData(){
      await this.handleInput()

      if(this.check.maxLength && this.check.hasNumber && this.check.hasLowerCase && this.check.hasUpperCase && this.check.hasSpecial){
        this.$store.dispatch(UPDATE_PASSWORD_PROFILE_INFO,{
          new_password: this.passwordsArr.newPassword,
          old_password: this.passwordsArr.currentPassword,
          new_password_confirmation: this.passwordsArr.confirmPassword,
        }).then(() => {
          this.resetSettingsData()
        })
      }
    },

    replaceEmailUsername(email, replaceChar = '*') {
      let atIndex = email.indexOf('@') // Находим позицию символа "@"
      let username = email.substring(0, atIndex) // Получаем часть email до символа "@"
      let maskedUsername = username.substring(0, Math.floor(username.length / 2)) + replaceChar.repeat(username.length - Math.floor(username.length / 2)) // Заменяем часть символов в имени пользователя на указанный символ
      let domain = email.substring(atIndex) // Получаем часть email после символа "@"
      return maskedUsername + domain // Соединяем замаскированное имя пользователя с доменом
    },

    handleInput(){
      this.check.maxLength = this.passwordsArr.newPassword && this.passwordsArr.newPassword.length >= 8
      this.check.hasNumber = /\d/.test(this.passwordsArr.newPassword)
      this.check.hasLowerCase = /[a-z]/.test(this.passwordsArr.newPassword)
      this.check.hasUpperCase = /[A-Z]/.test(this.passwordsArr.newPassword)
      this.check.hasSpecial = /[!@#$%^&*)(+=._-]/.test(this.passwordsArr.newPassword)
      this.check.confirmed = this.passwordsArr.newPassword === this.passwordsArr.confirmPassword
    },

    handleSocialConnect(type) {
      switch (type) {
      case 'google':
        var userEmail = this.$auth.user().email
        var redirectTo = `${process.env.VUE_APP_URL}/client/dashboard/settings`

        window.location.href = `${process.env.VUE_APP_URL}/oauth/google/auth?redirect_to=${encodeURIComponent(redirectTo)}&connected_user_email=${userEmail}`
        break
      case 'facebook':
        //
        break
      case 'apple':
        //
        break
      }
    },
    handleSocialDisconnect(type) {
      this.ssoActionState = true

      this.$store.dispatch(DISCONNECT_USER_SSO_SERVICE, { service: type }).then(() => {
        //
      }).catch(() => {
        //
      }).finally(() => {
        this.ssoActionState = false
      })
    },
  }
}
</script>


