export default {
  methods: {
    checkInputHasError(fieldName) {
      switch (this.validationErrors.code) {
      case 422:
        return this.validationErrors.errors && this.validationErrors.errors[fieldName] !== undefined
      default:
        return false
      }
    },
    checkWidgetInputHasError(fieldName) {
      switch (this.validationErrors.code) {
      case 422:
        return this.validationErrors.errors && this.validationErrors.errors[fieldName] !== undefined
      default:
        return false
      }
    },
  }
}
