<template>
  <div class="errors-container">
    <div
      class="wrap-error"
      v-for="(errors, field) in fieldsErrors"
      :key="field">
      <p
        class="sup-text"
        v-for="(error, key) in errors"
        :key="key">{{ error }}</p>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'

export default {
  name: 'ValidateErrors',
  props: ['fieldsErrors'],
}
</script>
