<template>
  <div class="settings-notifications-container">
    <h2>Notifications</h2>

    <div class="settings-notifications-table">
      <div class="settings-notifications-table-head">
        <div
          class="settings-notifications-table-wrap settings-notifications-table-time-wrap settings-notifications-table-head-time">
          <span>Time</span>
        </div>
        <div
          class="settings-notifications-table-wrap settings-notifications-table-email-wrap settings-notifications-table-head-email">
          <span>Email</span>
        </div>
        <div
          class="settings-notifications-table-wrap settings-notifications-table-browser-wrap settings-notifications-table-head-browser">
          <span>Browser</span>
        </div>
      </div>
      <div class="settings-notifications-table-content">
        <div class="settings-notifications-table-content-wrap">
          <div class="settings-notifications-table-wrap settings-notifications-table-time-wrap settings-notifications-table-content-time">
            <span>Deposits</span>
          </div>
          <div
            class="settings-notifications-table-wrap settings-notifications-table-email-wrap settings-notifications-table-content-email">
            <div class="basic-checkbox-wrap">
              <label class="basic-checkbox-container">
                <input
                  @input="changeInputStatus()"
                  type="checkbox"
                  class="basic-checkbox"
                  v-model="profileInfo.notifications['notifications_settings.deposits.email']">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div
            class="settings-notifications-table-wrap settings-notifications-table-browser-wrap settings-notifications-table-content-browser">
            <div class="basic-checkbox-wrap">
              <label class="basic-checkbox-container">
                <input
                  @input="changeInputStatus()"
                  type="checkbox"
                  class="basic-checkbox"
                  v-model="profileInfo.notifications['notifications_settings.deposits.browser']">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="settings-notifications-table-content-wrap">
          <div class="settings-notifications-table-wrap settings-notifications-table-time-wrap settings-notifications-table-content-time">
            <span>Distributions</span>
          </div>
          <div
            class="settings-notifications-table-wrap settings-notifications-table-email-wrap settings-notifications-table-content-email">
            <div class="basic-checkbox-wrap">
              <label class="basic-checkbox-container">
                <input
                  @input="changeInputStatus()"
                  type="checkbox"
                  class="basic-checkbox"
                  v-model="profileInfo.notifications['notifications_settings.distributions.email']">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div
            class="settings-notifications-table-wrap settings-notifications-table-browser-wrap settings-notifications-table-content-browser">
            <div class="basic-checkbox-wrap">
              <label class="basic-checkbox-container">
                <input
                  @input="changeInputStatus()"
                  type="checkbox"
                  class="basic-checkbox"
                  v-model="profileInfo.notifications['notifications_settings.distributions.browser']">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="settings-notifications-table-content-wrap">
          <div class="settings-notifications-table-wrap settings-notifications-table-time-wrap settings-notifications-table-content-time">
            <span>Withdrawals</span>
          </div>
          <div
            class="settings-notifications-table-wrap settings-notifications-table-email-wrap settings-notifications-table-content-email">
            <div class="basic-checkbox-wrap">
              <label class="basic-checkbox-container">
                <input
                  @input="changeInputStatus()"
                  type="checkbox"
                  class="basic-checkbox"
                  v-model="profileInfo.notifications['notifications_settings.withdrawals.email']">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div
            class="settings-notifications-table-wrap settings-notifications-table-browser-wrap settings-notifications-table-content-browser">
            <div class="basic-checkbox-wrap">
              <label class="basic-checkbox-container">
                <input
                  @input="changeInputStatus()"
                  type="checkbox"
                  class="basic-checkbox"
                  v-model="profileInfo.notifications['notifications_settings.withdrawals.browser']">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ErrorMessages v-if="errorMessage" :message="errorMessage"/>

    <SettingsActionButtons
      :inputChanged="inputChanged"
      @saveSettingsData="saveSettingsData()"
      @resetSettingsData="resetSettingsData"/>
  </div>
</template>

<script>
import SettingsActionButtons from "@/components/dashboard/settings/SettingsActionButtons"
import changeInputStatus from "@/mixins/settings/changeInputStatus"
import ErrorMessages from "@/components/basic/ErrorMessages"
import {mapGetters} from "vuex"
import {RESET_NOTIFICATIONS_PROFILE_INFO} from "@/store/mutations"
import {UPDATE_NOTIFICATIONS_PROFILE_INFO} from "@/store/actions"
// import helperFunctions from "@/common/helperFunctions"

export default {
  name: "SettingsNotifications",
  components: {
    ErrorMessages,
    SettingsActionButtons,
  },
  mixins: [
    changeInputStatus,
  ],
  computed: {
    ...mapGetters([
      'validationErrors',
      'profileInfo',
    ])
  },
  data() {
    return {
      errorMessage: false,
    }
  },
  methods: {
    resetSettingsData() {
      this.$store.commit(RESET_NOTIFICATIONS_PROFILE_INFO)
      this.inputChanged = false
    },
    saveSettingsData(){
      this.$store.dispatch(UPDATE_NOTIFICATIONS_PROFILE_INFO,{
        ['notifications_settings.deposits.email']: this.profileInfo.notifications['notifications_settings.deposits.email'],
        ['notifications_settings.deposits.browser']: this.profileInfo.notifications['notifications_settings.deposits.browser'],
        ['notifications_settings.distributions.email']: this.profileInfo.notifications['notifications_settings.distributions.email'],
        ['notifications_settings.distributions.browser']: this.profileInfo.notifications['notifications_settings.distributions.browser'],
        ['notifications_settings.withdrawals.email']: this.profileInfo.notifications['notifications_settings.withdrawals.email'],
        ['notifications_settings.withdrawals.browser']: this.profileInfo.notifications['notifications_settings.withdrawals.browser'],
      })
    },
  }
}
</script>
