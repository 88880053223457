<template>
  <div class="setting-profile-container">
    <div class="setting-profile-edit-info-row">
      <div class="setting-profile-photo-wrap">
        <div class="setting-profile-photo-image">
          <img :src="getUserAvatar()" alt="">
        </div>
        <div class="setting-profile-photo-btns-wrap">
          <div class="setting-profile-photo-btns setting-profile-btns-wrap">

            <div class="upload-wrap">
              <label for="files" class="basic_green-btn">Upload new photo</label>
              <input
                @change="uploadFile()"
                @input="avatarChanged = true"
                id="files"
                style="visibility:hidden;width: 0;"
                type="file"
                ref="avatar"
                accept="image/jpg, image/jpeg, image/png">
            </div>

            <button @click="setStandardIcon()" class="reset_btn">Reset</button>

          </div>
          <div class="setting-profile-photo-text">
            <p>Allowed JPG, GIF or PNG. Max size of 800K</p>
          </div>
        </div>
      </div>

      <template v-if="profileInfo.profile.account_type === 'institutional'">
        <div class="setting-profile-company-wrap">
          <div
            class="basic-form-wrap basic-input-wrap company-name-input-wrap">
            <div class="basic-form-field-block">
              <input
                :class="{ error: checkInputHasError('company_name') }"
                @input="changeInputStatus()"
                v-model="profileInfo.profile.company_name"
                type="text"
                placeholder="Company name"
                id="settings-company-name">
              <label for="settings-first-name">Company name</label>
            </div>
          </div>
          <div class="basic-form-wrap basic-input-wrap company-number-input-wrap">
            <div class="basic-form-field-block">
              <input
                :class="{ error: checkInputHasError('company_number') }"
                @input="changeInputStatus()"
                v-model="profileInfo.profile.company_number"
                type="text"
                placeholder="Company number"
                id="settings-company-number">
              <label for="settings-company-number">Company number</label>
            </div>
          </div>
        </div>
      </template>

      <div :class="`setting-profile-name-wrap setting-profile-name-wrap-${profileInfo.profile.account_type}`">
        <div class="basic-select-wrap title-select-wrap">
          <v-select
            :class="{ error: checkInputHasError('title') }"
            @input="changeInputStatus()"
            label="Select title"
            :items="titles"
            v-model="profileInfo.profile.title"
          ></v-select>
        </div>
        <div
          class="basic-form-wrap basic-input-wrap first-name-input-wrap">
          <div class="basic-form-field-block">
            <input
              :class="{ error: checkInputHasError('first_name') }"
              @input="changeInputStatus()"
              v-model="profileInfo.profile.first_name"
              type="text"
              placeholder="First name"
              id="settings-first-name"
              disabled
              readonly>
            <label for="settings-first-name">First name</label>
          </div>
        </div>
        <div class="basic-form-wrap basic-input-wrap last-name-input-wrap">
          <div class="basic-form-field-block">
            <input
              :class="{ error: checkInputHasError('last_name') }"
              @input="changeInputStatus()"
              v-model="profileInfo.profile.last_name"
              type="text"
              placeholder="Last name"
              id="settings-last-name"
              disabled
              readonly>
            <label for="settings-last-name">Last name</label>
          </div>
        </div>
      </div>

      <div class="setting-profile-timezone-wrap">
        <div class="basic-select-wrap">
          <v-select
            :class="{ error: checkInputHasError('timezone') }"
            @input="changeInputStatus()"
            label="Select time zone"
            :items="getTimezonesArr()"
            v-model="profileInfo.profile.timezone"
          ></v-select>
        </div>
      </div>

      <ValidateErrors :fieldsErrors="validationErrors.errors"/>

      <SettingsActionButtons
        :inputChanged="inputChanged"
        @saveSettingsData="saveSettingsData()"
        @resetSettingsData="resetSettingsData"/>
    </div>
    <div class="setting-profile-delete-account-row setting-profile-btns-wrap">
      <h2>Delete Account</h2>

      <div class="basic-checkbox-wrap">
        <label class="basic-checkbox-container">
          <input type="checkbox" class="basic-checkbox" v-model="approveDeactivateStatus">
          <span class="checkbox-text">I confirm my account deactivation</span>
          <span class="checkmark"></span>
        </label>
      </div>

      <button
        class="basic_green-btn"
        :class="{disabled: !approveDeactivateStatus}"
        :disabled="!approveDeactivateStatus"
        @click="deactivateAccount()">Deactivate Account</button>

      <ErrorMessages v-if="errorDeactivateMessage" :message="errorDeactivateMessage"/>
    </div>
  </div>
</template>

<script>
import SettingsActionButtons from "@/components/dashboard/settings/SettingsActionButtons"
import changeInputStatus from "@/mixins/settings/changeInputStatus"
import ErrorMessages from "@/components/basic/ErrorMessages"
import {mapGetters} from "vuex"
import gmtTimezones from "@/mixins/gmtTimezones"
import {
  REMOVE_ALL_PUSH_NOTIFICATION,
  RESET_GENERAL_PROFILE_INFO,
} from "@/store/mutations"
import {
  DELETE_ACCOUNT,
  UNSUBSCRIBE_FROM_USER_CHANNEL,
  UPDATE_GENERAL_PROFILE_INFO,
} from "@/store/actions"
import checkInputHasError from "@/mixins/checkInputHasError"
import ValidateErrors from "@/components/basic/ValidateErrors"
import HelperFunctions from "@/common/helperFunctions"
// import axios from 'axios'

export default {
  name: "SettingsProfile",
  components:{
    ValidateErrors,
    ErrorMessages,
    SettingsActionButtons,
  },
  mixins:[
    changeInputStatus,
    gmtTimezones,
    checkInputHasError,
  ],
  computed: {
    ...mapGetters([
      'validationErrors',
      'profileInfo',
      'initialProfileInfo',
    ])
  },
  data(){
    return{
      avatar: null,
      avatarPreview: null,
      avatarChanged: false,
      defaultImage: require('@/assets/img/user_photo_example.png'),
      titles: ['MR', 'MRS', 'MS', 'MISS', 'DR.', 'LADY', 'MADAM', 'PROF.', 'SIR.'],
      approveDeactivateStatus: false,
      errorDeactivateMessage: false,
    }
  },
  methods:{
    setStandardIcon(){
      this.avatarChanged = true
      this.avatar = null
      this.avatarPreview = null
    },
    getUserAvatar(){
      if (this.avatarChanged) {
        return this.avatarPreview ?? this.defaultImage
      } else {
        return this.profileInfo.profile.avatar
          ? process.env.VUE_APP_API_URL + this.profileInfo.profile.avatar
          : this.defaultImage
      }
    },
    resetSettingsData(){
      this.$store.commit(RESET_GENERAL_PROFILE_INFO)
      this.inputChanged = false
    },
    saveSettingsData(){
      let formData = new FormData()

      formData.append('avatar', this.avatar)
      formData.append('title', this.profileInfo.profile.title)
      formData.append('first_name', this.profileInfo.profile.first_name)
      formData.append('last_name', this.profileInfo.profile.last_name)
      formData.append('timezone', this.profileInfo.profile.timezone)

      if (this.profileInfo.profile.account_type === 'institutional') {
        formData.append('company_name', this.profileInfo.profile.company_name)
        formData.append('company_number', this.profileInfo.profile.company_number)
      }

      this.$store.dispatch(UPDATE_GENERAL_PROFILE_INFO, formData).then(() => {
        //
      }).catch(() => {
        //
      }).finally(() => {
        if(!this.validationErrors.errors){
          this.$auth.fetch()
        }
      })

      // window.location.reload()
    },
    uploadFile(){
      let input = event.target

      // Check if file is selected
      if (input.files && input.files[0]) {
        // Prepare for preview
        let reader = new FileReader()
        let vm = this

        reader.onload = function(e) {
          vm.avatarPreview = e.target.result
        }

        reader.readAsDataURL(input.files[0])

        // Prepare for request
        this.avatar = input.files[0]
      }
    },
    async deactivateAccount(){
      if(this.approveDeactivateStatus){
        await this.$store.dispatch(DELETE_ACCOUNT)
        this.$store.dispatch(UNSUBSCRIBE_FROM_USER_CHANNEL, { userId: this.$auth.user().id })
        this.$store.commit(REMOVE_ALL_PUSH_NOTIFICATION)
        this.$auth.logout()
      }

      if(this.validationErrors.errors){
        this.errorDeactivateMessage = this.validationErrors.errors
      }
    },
    getTimezonesArr(){
      return HelperFunctions.extractColumn(this.gmtTimezones, 'label')
    }
  }
}
</script>
