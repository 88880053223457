<template>
  <div class="setting-contacts-container setting-billing-container">
    <h2>Billing Address</h2>

    <div class="setting-contacts-fields-row">
      <div class="setting-contacts-wrap setting-contacts-name-email-wrap">
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('customer_name') }"
              v-model="profileInfo.payment['payment_information.customer_name']"
              type="text"
              placeholder="[First name + Last name]"
              id="billing-company-name">
            <label for="billing-company-name">Сustomer name</label>
          </div>
        </div>
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('email') }"
              v-model="profileInfo.payment['payment_information.email']"
              type="text"
              placeholder="Billing email"
              id="billing-email">
            <label for="billing-email">Billing email</label>
          </div>
        </div>
      </div>

      <div class="setting-contacts-wrap setting-contacts-tax-wrap">
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('tax_id') }"
              v-model="profileInfo.payment['payment_information.tax_id']"
              type="text"
              placeholder="TAX ID"
              id="billing-tax-id">
            <label for="billing-tax-id">TAX ID</label>
          </div>
        </div>
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('city') }"
              v-model="profileInfo.payment['payment_information.city']"
              type="text"
              placeholder="City"
              id="billing-city">
            <label for="billing-city">City</label>
          </div>
        </div>
      </div>

      <div class="setting-contacts-wrap setting-contacts-country-state-wrap setting-contacts-select-wrap">
        <div class="basic-select-wrap">
          <CountryField
            id="country"
            @manualChange="changeInputStatus()"
            @reset="resetSettingsData()"
            v-model="profileInfo.payment['payment_information.country']"/>
        </div>
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('state') }"
              v-model="profileInfo.payment['payment_information.state']"
              type="text"
              placeholder="State"
              id="billing-state">
            <label for="billing-state">State</label>
          </div>
        </div>
      </div>

      <div class="setting-contacts-wrap setting-contacts-address-zip-wrap">
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('address') }"
              v-model="profileInfo.payment['payment_information.address']"
              type="text"
              placeholder="Billing address"
              id="billing-address">
            <label for="billing-address">Billing address</label>
          </div>
        </div>
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('postal_code') }"
              v-model="profileInfo.payment['payment_information.postal_code']"
              type="text"
              placeholder="Zip code"
              id="billing-zip">
            <label for="billing-zip">Zip code</label>
          </div>
        </div>
      </div>
    </div>

    <ValidateErrors :fieldsErrors="validationErrors.errors"/>

    <SettingsActionButtons
      :inputChanged="inputChanged"
      @saveSettingsData="saveSettingsData()"
      @resetSettingsData="resetSettingsData"/>
  </div>
</template>

<script>
import SettingsActionButtons from "@/components/dashboard/settings/SettingsActionButtons"
import changeInputStatus from "@/mixins/settings/changeInputStatus"
import {mapGetters} from "vuex"
import CountryField from "@/components/CountryField"
import ValidateErrors from "@/components/basic/ValidateErrors"
import checkInputHasError from "@/mixins/checkInputHasError"
import {
  UPDATE_PAYMENT_PROFILE_INFO,
} from "@/store/actions"
import {
  RESET_PAYMENT_PROFILE_INFO
} from "@/store/mutations"

export default {
  name: "SettingsTaxesAndPayments",
  components: {
    ValidateErrors,
    CountryField,
    SettingsActionButtons,
  },
  mixins:[
    changeInputStatus,
    checkInputHasError,
  ],
  computed: {
    ...mapGetters([
      'validationErrors',
      'profileInfo',
      'initialProfileInfo',
    ])
  },
  data(){
    return{
      errorMessage: false,
    }
  },
  methods:{
    resetSettingsData(){
      this.$store.commit(RESET_PAYMENT_PROFILE_INFO)
      this.inputChanged = false
    },
    saveSettingsData(){
      let preparedCountryField

      if (
        typeof this.profileInfo.payment['payment_information.country'] === 'object' &&
        !Array.isArray(this.profileInfo.payment['payment_information.country']) &&
        this.profileInfo.payment['payment_information.country'] !== null
      ) {
        preparedCountryField = this.profileInfo.payment['payment_information.country'].iso2
      } else {
        preparedCountryField = this.profileInfo.payment['payment_information.country']
      }

      this.$store.dispatch(UPDATE_PAYMENT_PROFILE_INFO,{
        ['payment_information.customer_name']: this.profileInfo.payment['payment_information.customer_name'],
        ['payment_information.email']: this.profileInfo.payment['payment_information.email'],
        ['payment_information.tax_id']: this.profileInfo.payment['payment_information.tax_id'],
        ['payment_information.city']: this.profileInfo.payment['payment_information.city'],
        ['payment_information.country']: preparedCountryField,
        ['payment_information.state']: this.profileInfo.payment['payment_information.state'],
        ['payment_information.address']: this.profileInfo.payment['payment_information.address'],
        ['payment_information.postal_code']: this.profileInfo.payment['payment_information.postal_code'],

      })
    },
  }
}
</script>
