<template>
  <div class="setting-profile-btns-wrap">
    <button class="basic_green-btn" @click="$emit('saveSettingsData')">Save changes</button>
    <button class="reset_btn" :disabled="!inputChanged" @click="resetSettingsData()">Reset</button>
  </div>
</template>

<script>
export default {
  name: "SettingsActionButtons",
  props:[
    'inputChanged'
  ],
  methods:{
    resetSettingsData(){
      this.$emit('resetSettingsData')
    }
  }
}
</script>
