<template>
  <p class="error_text">{{ message }}</p>
</template>

<script>
export default {
  name: "ErrorMessages",
  props: ['message'],
}
</script>
