export default {
  data(){
    return{
      inputChanged: false,
    }
  },
  methods:{
    changeInputStatus(){
      this.inputChanged = true
    }
  }
}
