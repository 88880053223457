<template>
  <user-dashboard-layout>
    <div class="dashboard-settings-container scroll-container">
      <p v-if="loader" class="loader"></p>
      <tabs v-else :options="{ useUrlFragment: false }" @changed="tabChanged">
        <tab name="Profile" id="profile">
          <SettingsProfile v-if="profileInfo.profile"/>
        </tab>

        <tab name="Contacts" id="contacts">
          <SettingsContacts v-if="profileInfo.contacts"/>
        </tab>

        <tab name="Verification Status" id="verificationStatus">
          <SettingsStatus/>
        </tab>

        <tab name="Security" id="security">
          <SettingsSecurity/>
        </tab>

        <tab name="Taxes & Payments" id="billingPayments">
          <SettingsTaxesAndPayments v-if="profileInfo.payment"/>
        </tab>

        <tab name="Notifications" id="notifications">
          <SettingsNotifications v-if="profileInfo.notifications"/>
        </tab>
      </tabs>
    </div>
  </user-dashboard-layout>

</template>

<script>
import UserDashboardLayout from "@/layouts/UserDashboardLayout"
import { Tabs, Tab } from 'vue-tabs-component'
import SettingsProfile from "@/components/dashboard/settings/SettingsProfile"
import SettingsContacts from "@/components/dashboard/settings/SettingsContacts"
import SettingsStatus from "@/components/dashboard/settings/SettingsStatus"
import SettingsSecurity from "@/components/dashboard/settings/SettingsSecurity"
import SettingsTaxesAndPayments from "@/components/dashboard/settings/SettingsTaxesAndPayments"
import SettingsNotifications from "@/components/dashboard/settings/SettingsNotifications"
import { mapGetters } from "vuex"
import {
  FETCH_PROFILE_INFO,
} from "@/store/actions"
import isEmptyField from "@/mixins/isEmptyField"
import {
  RESET_VALIDATION_ERROR,
} from "@/store/mutations"
import redirectUserByRole from "../../mixins/redirectUserByRole"

export default {
  name: "Settings",
  components:{
    SettingsNotifications,
    SettingsTaxesAndPayments,
    SettingsSecurity,
    SettingsStatus,
    SettingsContacts,
    SettingsProfile,
    UserDashboardLayout,
    Tabs,
    Tab
  },
  mixins:[
    isEmptyField,
    redirectUserByRole,
  ],
  computed: {
    ...mapGetters([
      'profileInfo',
      'initialProfileInfo',
    ])
  },
  data(){
    return{
      loader: false
    }
  },
  mounted() {
    this.fetchProfileInfo()
  },
  methods:{
    async fetchProfileInfo() {
      this.loader = true
      await this.$store.dispatch(FETCH_PROFILE_INFO, [])
      this.loader = false
    },
    tabChanged () {
      this.$store.commit(RESET_VALIDATION_ERROR)
    },
  }
}
</script>
