<template>
  <div class="setting-contacts-container">
    <h2>Contact Information</h2>

    <div class="setting-contacts-fields-row">
      <div class="setting-contacts-wrap">
        <div class="basic-form-wrap basic-input-wrap">
          <!--          :class="{ error: checkInputHasError('arguments.arg_83') }"-->
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('email') }"
              v-model="profileInfo.contacts.email"
              type="text"
              placeholder="Email"
              id="contact-first-email">
            <label for="contact-first-email">Email</label>
          </div>
        </div>
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('phone') }"
              v-model="profileInfo.contacts.phone"
              type="text"
              placeholder="Phone number"
              id="contact-first-phone">
            <label for="contact-first-phone">Phone number</label>
          </div>
        </div>
      </div>

      <div class="setting-contacts-wrap">
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('city') }"
              v-model="profileInfo.contacts.city"
              type="text"
              placeholder="City"
              id="contact-first-city">
            <label for="contact-first-city">City</label>
          </div>
        </div>
        <div class="basic-select-wrap">
          <CountryField
            id="country"
            @manualChange="changeInputStatus()"
            @reset="resetSettingsData()"
            v-model="profileInfo.contacts.country"/>
        </div>
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('address') }"
              v-model="profileInfo.contacts.address"
              type="text"
              placeholder="Address"
              id="contact-first-address">
            <label for="contact-first-address">Address</label>
          </div>
        </div>
      </div>

      <div class="setting-contacts-wrap">
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('state') }"
              v-model="profileInfo.contacts.state"
              type="text"
              placeholder="State"
              id="contact-first-state">
            <label for="contact-first-state">State</label>
          </div>
        </div>
        <div class="basic-form-wrap basic-input-wrap">
          <div class="basic-form-field-block">
            <input
              @input="changeInputStatus()"
              :class="{ error: checkInputHasError('postal_code') }"
              v-model="profileInfo.contacts.postal_code"
              type="text"
              placeholder="Zip code"
              id="contact-first-zip">
            <label for="contact-first-zip">Zip code</label>
          </div>
        </div>
      </div>
    </div>

    <ValidateErrors :fieldsErrors="validationErrors.errors"/>

    <SettingsActionButtons
      :inputChanged="inputChanged"
      @saveSettingsData="saveSettingsData()"
      @resetSettingsData="resetSettingsData"/>
  </div>
</template>

<script>
import SettingsActionButtons from "@/components/dashboard/settings/SettingsActionButtons"
import changeInputStatus from "@/mixins/settings/changeInputStatus"
import {mapGetters} from "vuex"
import {RESET_CONTACTS_PROFILE_INFO} from "@/store/mutations"
import ValidateErrors from "@/components/basic/ValidateErrors"
import {
  UPDATE_CONTACTS_PROFILE_INFO,
} from "@/store/actions"
import checkInputHasError from "@/mixins/checkInputHasError"
import CountryField from "@/components/CountryField"

export default {
  name: "SettingsContacts",
  components: {
    CountryField,
    ValidateErrors,
    SettingsActionButtons
  },
  mixins:[
    changeInputStatus,
    checkInputHasError,
  ],
  computed: {
    ...mapGetters([
      'validationErrors',
      'profileInfo',
      'initialProfileInfo',
    ])
  },
  data(){
    return{
      errorMessage: false,
    }
  },
  methods:{
    resetSettingsData(){
      this.$store.commit(RESET_CONTACTS_PROFILE_INFO)
      this.inputChanged = false
    },
    saveSettingsData(){
      let preparedCountryField

      if (
        typeof this.profileInfo.contacts.country === 'object' &&
        !Array.isArray(this.profileInfo.contacts.country) &&
        this.profileInfo.contacts.country !== null
      ) {
        preparedCountryField = this.profileInfo.contacts.country.iso2
      } else {
        preparedCountryField = this.profileInfo.contacts.country
      }

      this.$store.dispatch(UPDATE_CONTACTS_PROFILE_INFO,{
        email: this.profileInfo.contacts.email,
        phone: this.profileInfo.contacts.phone,
        city: this.profileInfo.contacts.city,
        country: preparedCountryField,
        address: this.profileInfo.contacts.address,
        state: this.profileInfo.contacts.state,
        postal_code: this.profileInfo.contacts.postal_code,
      })
    },
  }
}
</script>
