<template>
  <div class="settings-verification-status-container">
    <template v-if="checkUserDocumentsVerified()">
      <div class="settings-verification-status-block">
        <div class="settings-verification-status-row">
          <h2>Verification Completed</h2>
          <p>We are pleased to inform that your account has been successfully verified.</p>
          <p class="green_text">You are welcome to start your investment journey!</p>
          <p>Please feel free to reach us if you need some assistance, we are always at your service.</p>
        </div>
        <img src="@/assets/img/verification_completed_image.png" alt="">
      </div>
    </template>

    <template v-else>
      <div v-if="!showDocumentsVerificationInfo && !showDocumentsVerificationError" class="dashboard-verification-content-block-scroll">
        <h2>Identy Verification</h2>
        <p>Please get your passport or ID ready to complete secure and automated identity verification process by clicking the button below</p>

        <AmlbotVerificationButton/>
      </div>

      <div v-else class="dashboard-verification-pending-wrap d-flex flex-column flex-1">
        <template v-if="showDocumentsVerificationInfo && !showDocumentsVerificationError">
          <IdentityVerificationInProgress/>
        </template>

        <template v-if="!showDocumentsVerificationInfo && showDocumentsVerificationError">
          <IdentityVerificationError @restartDashboardVerification="tryVerificationAgain"/>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import checkUserVerification from "@/mixins/checkUserVerification"
import AmlbotVerificationButton from "@/components/auth/AmlbotVerificationButton"
import IdentityVerificationInProgress from "@/components/dashboard/common/IdentityVerificationInProgress"
import IdentityVerificationError from "@/components/dashboard/common/IdentityVerificationError"
import {eventBus} from "../../../main"

export default {
  name: "SettingsStatus",
  components: {
    IdentityVerificationError,
    IdentityVerificationInProgress,
    AmlbotVerificationButton
  },
  mixins:[
    checkUserVerification,
  ],
  data(){
    return{
      showDocumentsVerificationInfo: false,
      showDocumentsVerificationError: false,
    }
  },
  created() {
    eventBus.$on('UserDocumentsVerificationPending', () => {
      // Refresh the page
      this.$router.go()
    })
    eventBus.$on('userDocumentsVerificationError', () => {
      // Refresh the page
      this.$router.go()
    })
    eventBus.$on('userDocumentsVerificationSuccess', () => {
      // Refresh the page
      this.$router.go()
    })
  },
  mounted() {
    //
  },
  methods:{
    catchDocumentsVerificationError(){
      this.showDocumentsVerificationInfo = false
      this.showDocumentsVerificationError = true
    },
    catchDocumentsVerificationInProgress(){
      this.showDocumentsVerificationInfo = true
      this.showDocumentsVerificationError = false
    },
    tryVerificationAgain(){
      this.showDocumentsVerificationInfo = false
      this.showDocumentsVerificationError = false
    },
  }
}
</script>
